import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-19f4baf79bfb4bb9c49c4588eaf84d9ae2a82a28/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-19f4baf79bfb4bb9c49c4588eaf84d9ae2a82a28/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-19f4baf79bfb4bb9c49c4588eaf84d9ae2a82a28/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-19f4baf79bfb4bb9c49c4588eaf84d9ae2a82a28/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-19f4baf79bfb4bb9c49c4588eaf84d9ae2a82a28/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/usr/src/packages/BUILD/hedgedoc2/src/hedgedoc-19f4baf79bfb4bb9c49c4588eaf84d9ae2a82a28/node_modules/next/dist/client/components/render-from-template-context.js");
